<template>
<div >
      <NavBar
  title="套餐详情"
  left-arrow
  @click-left="$router.go(-1)"
/>
  <vimg :src="'https://guanjietong.rilintech.com/weixin_new/packages/np'+id+'.jpg?+_t='+getRandomInt(1000,9999)" />
</div>
</template>
<script>
import {Image as vimg} from 'vant';
//https://guanjietong.rilintech.com/weixin_new/packages/p1.png
export default {
  name: 'PakageDeatilimg',
  components: {
    vimg
  },
  data(){
    return {
       id:'0'
    }
  },
  methods:{  
     getRandomInt(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
  },
  watch: {
    // 监听路由对象中的query属性
    '$route.query': {
      immediate: true, // 启用立即监听
      handler(newQuery) {
        // 当query发生变化时，更新data中的queryValue
        this.id = newQuery.id || '0';
      }
    }
  },
   created() {
    
  },
}
</script>

<style scoped>
.packege_title{
    font-size: medium;
}

.packege_desc{
    font-size: small;
    color:#646566;
}
.pakagetitle{
    text-align: center;
}

</style>
